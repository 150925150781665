<template>
  <div v-if="slider != null" id="catBanner">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="5000"
      indicators
      img-width="1920"
      img-height="300"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      class="hero-banner-carousel category"
    >
      <b-carousel-slide
        v-for="(bSlide, index) in slider.slides"
        :key="index"
        :style="{ backgroundImage: `url(${bSlide.optImage})` }"
      >
        <div class="container">
          <span class="d-block text-uppercase text-light font-weight-normal">{{
            bSlide.title
          }}</span>
          <v-runtime-template
            v-if="bSlide.description != null"
            :template="bSlide.description"
          ></v-runtime-template>

          <b-link
            v-if="bSlide.link != null"
            :to="bSlide.link"
            class="btn btn-blue ml-md-0"
            >{{ bSlide.buttonText }}</b-link
          >
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
import { isBrowser } from "mobile-device-detect";
import { mapGetters } from "vuex";
export default {
  name: "CatBanner",
  components: { VRuntimeTemplate },
  data: () => ({
    slide: 0,
    sliding: false,
  }),
  computed: {
    ...mapGetters({ slider1: "category/getSlider" }),
    slider() {
      let slider = this.slider1;

      if (slider != null) {
        if (slider.slides != null) {
          slider.slides.forEach((element) => {
            if (this.mobileCheck == "browser") {
              element.optImage = element.media.url;
            } else if (this.mobileCheck == "tablet") {
              element.optImage = element.media.larger;
            } else {
              element.optImage = element.media.medium;
            }
          });
        }
      }

      return slider;
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>


