<template>
  <CatBanner />
</template>

<script>
import CatBanner from "@/base/core/components/CatBanner";

export default {
  name: "AdvertisingBanner",
  components: { CatBanner },

  data: () => ({}),
};
</script>


